export default {
    header: {
        login: "Iniciar sesión",
        signUp: "Registrarse",
    },

    login: {
        h2: "Iniciar sesión en la cuenta",
        placeholder: {
            email: "Correo electrónico",
            password: "Contraseña",
            code_email: "Ingrese el código del correo electrónico",
            code_google: "Ingrese el código de Google Authenticator"
        },
        forgot: "¿Olvidó su contraseña?",
        signUp: {
            text: "¿No es miembro?",
            link: "Registrarse",
        },
        submit: "Iniciar sesión",

        validation: {
            req: "Este campo es obligatorio",
            email: "Este campo debe ser un correo electrónico válido",
            password: "Este campo debe ser una contraseña válida (a-z, A-Z, 0-9)",
            code: "Este campo debe ser un código de 4 dígitos"
        },
        errors: {
            check: "Verificar campo del formulario",
        }
    },

    twoFaLinkActivation: {
        title: "Autenticación de dos factores",
        text: "Por favor, espere..."
    },

    register: {
        h2: "Crear una cuenta",
        placeholder: {
            email: "Correo electrónico",
            phone: "Teléfono",
            password_repeat: "Repita la contraseña",
            password: "Contraseña"
        },
        login: {
            text: "¿Ya es miembro?",
            link: "Iniciar sesión",
        },

        submit: "Crear cuenta",

        validation: {
            req: "Este campo es obligatorio",
            email: "Este campo debe ser un correo electrónico válido",
            phone: "Este campo debe ser un teléfono válido",
            password: "Este campo debe ser una contraseña válida (a-z, A-Z, 0-9)"
        },

        confirmation: {
            h1: "Casi listo",
            p: {
                0: "Haga clic en el enlace de activación en su correo electrónico",
                1: "para confirmar el registro",
            },
            link: "Ir a Iniciar sesión"
        },

        errors: {
            check: "Verificar campo del formulario",
        }
    },

    home: {
        promoSection: {
            h1: "Puerta de enlace de procesamiento de pago de Bitcoin y monedero criptográfico de negocios",
            descLeft: {
                u: "Acepta pagos en Bitcoin",
                text: "Ethereum, USDT y otras criptomonedas con una puerta de enlace de criptomonedas líder."
            },
            descRight: "Reciba, envíe, almacene, cambie y pague en criptomoneda con un monedero criptográfico auditado por seguridad."
        },

        h2: "Todos los servicios criptográficos para empresas en un solo lugar",

        servicesSection: {
            0: {
                title: "Pasarela de pago",
                desc: "Nuestro sistema de pago criptográfico ayuda a cualquier tipo de negocio en línea",
                u: "aceptar pagos criptográficos",
                desc2: "y recibir una cantidad igual en la moneda nacional de su elección al instante o mantenerlo y pagar con criptomoneda a sus socios y afiliados."
            },
            1: {
                title: "Solución SaaS criptográfica",
                desc: "Esta es una oportunidad rentable para comenzar su propio negocio de pago con moneda digital y convertirse en un sistema de pago criptográfico o servicio de criptomoneda para comerciantes bajo su propia marca de la caja."
            },
            2: {
                title: "Intercambio",
                title2: "Mesa OTC",
                desc: "Las empresas pueden intercambiar BTC, ETH, USDT, XRP y 20+ criptomonedas más por docenas de monedas nacionales a las mejores tarifas y sin tarifas ocultas. Se admiten rampas on y off fiat.",
                desc2: "El OTC está disponible para operaciones de intercambio superiores a 1M EUR."
            },
            h3: "Conozca cómo nuestras soluciones ayudan al crecimiento de su negocio",
            3: {
                title: "Billetera caliente (custodia)",
                desc: "Sistema de billetera criptográfica caliente dirigido a empresas cuyo modelo requiere un entorno escalable y seguro para la gestión de activos digitales (aceptación, almacenamiento y retirada de activos digitales a múltiples direcciones)."
            }
        },

        h3: {
            0: "Enfoque personal",
            1: "para la adopción de Bitcoin",
            manage: "Administra tus activos digitales en una aplicación de billetera criptográfica auditada de seguridad."
        },

        wallet: {
            title: "billetera criptográfica",
            desc: {
                0: "Recibe, almacena y envía 15+ criptomonedas en 5 cadenas de bloques diferentes en la mejor billetera criptográfica. Intercambia todos los activos digitales admitidos a 16 monedas fiduciarias a las mejores tarifas.",
                1: "Compra Bitcoin y otra criptomoneda con tarjetas bancarias está por llegar."
            }
        }
    },

    dashboard: {
        sidebar: {
            title: "Panel de Comerciante",
            links: {
                dashboard: "Panel",
                profile: "Perfil",
                templates: "Plantillas de Factura",
                invoices: "Facturas",
                addresses: "Direcciones",
                wallets: "Billeteras / Retiro",
                withdrawRequests: "Solicitudes de Retiro",
                transactions: "Transacciones",
                callbacks: "Webhooks / Callbacks",
                api: "Gestión de API",
                logout: "Cerrar Sesión",
            },
        },

        main: {
            cards: {
                totalAvailableBalance: {
                    title: "Saldo Disponible Total",
                    footerText: "Todo el tiempo"
                },
                revenue: {
                    title: "Ingresos",
                    footerLinks: {
                        0: "Todo el tiempo",
                        1: "Mes",
                        2: "Semana",
                        3: "Hoy",
                    }
                }
            }
        },

        profile: {
            title: "Perfil",
            subtitle: "Perfil",

            info: {
                title: "Información de Usuario",
                email: "Correo Electrónico"
            },

            lang: {
                title: "Idioma",
                change: "Cambiar idioma"
            },

            expirations: {
                invoice: {
                    title: "Vencimientos",
                    change: "Vencimiento de factura"
                },

                invoiceAddress: {
                    title: "Vencimiento de dirección de factura",
                    hours: "horas"
                },

                minutes: "minutos",

                info: "Si su cliente paga accidentalmente después de que la factura haya expirado y si la tasa de cambio ha bajado, el pago no será intercambiado y puede reembolsar a su cliente. Si la tasa de cambio ha aumentado, el pago será intercambiado.",

                save: "Guardar configuración",

                error: "El vencimiento de la factura debe ser menor o igual que el vencimiento de la dirección de la factura"
            }
        },

        routeNames: {
            dashboard: "Panel",
            ProfilePage: "Perfil",
            InvoiceTemplatesList: "Lista de Plantillas de Factura",
            InvoicesForm: "Formulario de Facturas",
            InvoicesList: "Lista de Facturas",
            InvoiceTemplatesCreateForm: "Formulario de Plantillas de Factura",
            InvoiceTemplatesEditForm: "Formulario de Plantillas de Factura",
            AddressesList: "lista de direcciones",
            WalletsList: "Lista de Billeteras",
            WithdrawRequest: "Solicitud de Retiro",
            WithdrawRequestConfirm: "Confirmación de solicitud de retiro",
            WithdrawTransactions: "Transacciones de Retiro",
            TransactionsList: "Lista de Transacciones",
            ApiManagement: "Gestión de API",
            CallbacksList: "Lista de Webhooks",
        },

        templates: {
            table: {
                0: "ID",
                1: "Nombre de Plantilla",
                2: "Moneda",
                3: "Monto Solicitado",
                4: "Estado",
                5: "Acciones",
            },
            statuses: {
                0: "Creada",
                1: "Esperando Depósito",
                2: "Cancelada",
                3: "Completada"
            },
            noRecords: "No hay Registros",
            title: "Lista de Plantillas de Factura",
            subtitle: "Lista de Plantillas de Factura",
            createInvoiceTemplate: "Crear Plantilla de Factura",
            cardTemplate: {
                0: "Transacciones",
                1: "Código para incrustar",
                2: "Enlace",
                3: "Eliminar",
            },
            copy: {
                0: "Enlace para plantilla de factura",
                1: "fue copiado al portapapeles",
            },
            invoiceWidget: {
                title: "Pago",
                prodPurchase: "Compra de producto",
                btnSuccess: "Ir al pago",
                label: {
                    email: "Por favor, ingrese su correo electrónico",
                    choose: "Elija la moneda para pagar"
                },
                amount: "Monto del pago"
            },
            widgetModal: {
                title: "Código del widget de factura",

                copy: {
                    success: "El código del widget se ha copiado en el portapapeles",
                    error: "Error al copiar el texto"
                }
            }
        },

        invoiceTemplatesForm: {
            title: "Crear Plantilla de Factura",

            label: {
                name: "Nombre de la Plantilla de Factura",
                convert: "Convertir pagos a",
                price: "Precio en",
                currency: "Recibir pagos en",
                amount: "Cantidad Solicitada"
            },

            placeholder: {
                name: "Nombre de la Plantilla",
                price: "Precio en",
                currency: "Aceptar todas las monedas",
                amount: "Cantidad Solicitada"
            },

            success: {
                0: "Enlace para la plantilla de factura",
                1: "fue copiado al portapapeles",
            },

            submit: "Guardar y Copiar enlace de la factura"
        },

        invoicesList: {
            title: "Lista de Facturas",
            subtitle: "Lista de Facturas",

            table: {
                0: "ID",
                1: "Moneda",
                2: "Monto de la Factura",
                3: "Estado",
                4: "Fecha de Creación",
                5: "Acciones",
            },

            link: "Enlace",

            copy: {
                0: "Enlace para la factura",
                1: "fue copiado al portapapeles",
                error: "Error al copiar el texto"
            },

            createInvoice: "Crear Factura"
        },

        invoicesForm: {
            title: "Crear Factura",

            label: {
                currency: "Moneda",
                amount: "Cantidad Solicitada"
            },

            placeholder: {
                currency: "Elegir Moneda",
                amount: "Cantidad Solicitada"
            },

            success: {
                0: "La factura fue creada y el enlace para la factura",
                1: "fue copiado al portapapeles",
            },

            submit: "Guardar y Copiar enlace de la factura"
        },

        addresses: {
            statuses: {
                0: "Desactivado",
                1: "Activo",
                2: "Deprecated"
            },
            list: {
                title: "Lista de direcciones",
                subtitle: "Lista de direcciones",
                create: "Crear dirección",
                edit: "Editar",
                delete: "Eliminar",

                table: {
                    0: "ID",
                    1: "Nombre",
                    2: "Moneda",
                    3: "Convertir a",
                    4: "Mínimo de recarga",
                    5: "Dirección",
                    6: "Estado",
                    7: "Acción"
                },
            },
            form: {
                title: "Crear dirección",
                label: {
                    label: "Nombre",
                    currency: "Moneda",
                    convert_to: "Convertir a:",
                    network_type: "Tipo de red"
                },
                placeholder: {
                    label: "Nombre",
                    currency: "Moneda",
                    convert_to: "No convertir",
                    network_type: "Tipo de red"
                },
                submit: "Guardar dirección"
            }
        },

        wallets: {
            title: "Lista de Saldos de Billetera",
            subtitle: "Lista de Saldos de Billetera",

            tableColumns: {
                0: "Moneda",
                1: "Saldo Total",
                2: "Saldo Disponible",
                3: "Retirar",
                4: "Swap"
            },

            withdraw: "Retirar",
            swap: "Swap"
        },

        walletsRequest: {
            title: "Crear Solicitud de Retiro",
            label: {
                networkType: "Tipo de Red",
                address: "Dirección",
                amount: "Cantidad",
                iban: "IBAN"
            },
            placeholder: {
                networkType: "Tipo de Red",
                address: "Dirección",
                amount: "Cantidad",
                iban: "IBAN"
            },
            submit: "Retirar",

            detailTitle: 'Withdrawal Detail',

            confirmCode: {
                title: "Confirmación de solicitud de retiro",
                text: "Por favor confirme el retiro",
                labelEmail: "Revisa tu correo electrónico e ingresa el código",
                labelEmailSub: 'The code is valid for 5 minutes',
                labelGoogle: "Ingrese el código usando Google Authenticator",
                placeholderEmail: 'Introduce el código del correo',
                placeholderGoogle: "Ingrese el código de Google Authenticator"
            },
        },

        walletsRequestsList: {
            title: "Lista de solicitudes de retiro",
            subtitle: "Lista de solicitudes de retiro",

            tableColumns: {
                0: "ID",
                1: "Cantidad",
                2: "Moneda",
                3: "Comisión",
                4: "Dirección",
                5: "Estado",
                6: "Fecha",
                7: "Network"
            },

            statuses: {
                0: 'Creado',
                1: 'Enviado',
                2: 'En espera',
                3: 'Bajo revisión',
                4: 'Procesando',
                5: 'Confirmación',
                6: 'Borrado',
                7: 'Cancelado',
                11: 'Retirando',
                12: 'Bloqueo de proceso de retiro'
            }
        },

        transactionsList: {
            title: "Lista de transacciones",
            subtitle: "Lista de transacciones",

            tableColumns: {
                id: "Identificación",
                txid: "Hash",
                invoice_id: "Identificación de factura",
                direction: "Direction",
                amount_paid: "Cantidad pagada",
                fee: "Comisión",
                amount_converted: "Convertido a",
                convert_rate: "Tipo de cambio",
                item_name: "Nombre del artículo",
                address_name: "Nombre de la dirección",
                address_deposit: "Depósito de dirección",
                user_email: "Correo electrónico del cliente",
                time_created: "Fecha de creación",
                address_from: "Dirección de envío",
                action: "Acción",
                note: "Comment"
            },
            noteModal: {
                title: 'Transaction Comment',
                submit: 'Save Note'
            }
        },

        invoices: {
            statuses: {
                0: "Creado",
                1: "Esperando depósito",
                2: "Expirado",
                3: "Completado",
                4: "Expirado",
                5: "Dirección expirada",
                partiallyPaid: 'Partially Paid'
            }
        },

        callbacks: {
            statuses: {
                0: 'Desactivado',
                1: 'Activo'
            },

            form: {
                name: "Por favor, ingrese su URL de webhook",
                button: "Agregar URL de Callback",
                urlError: "Falta la URL del Callback"
            },

            list: {
                title: "Lista de Webhooks de Callback",
                subtitle: "Lista de Webhooks de Callback",

                tableColumns: {
                    0: "ID",
                    1: "URL del webhook",
                    2: "Estado",
                    3: "Acciones",
                },

                delete: "Eliminar"
            },

            copySecret: {
                title: "Callback guardado",
                info: "Por favor, guarde esta clave secreta en algún lugar seguro y accesible. Por razones de seguridad, no podrá verla de nuevo. Si pierde esta clave secreta, deberá generar una nueva.",

                secret: "Secreto",
                callbackSecret: "Secreto de callback de Liqpais",

                copy: {
                    btn: "COPIAR SECRETO DE CALLBACK",
                    success: "El secreto de callback se copió en el portapapeles",
                    error: "Error al copiar texto",
                }
            }
        },

        apiManagement: {
            list: "Lista de llaves",
            link: "Documentación de API",

            title: "Gestión de API",
            info: "Crear una clave privada de API proporciona acceso a los mercados y servicios de negociación en tiempo real en LiqPaid a través de un sitio o aplicación de terceros.",

            keysList: {
                title: "Lista de claves",
                subtitle: "Lista de claves",

                tableColumns: {
                    0: "Nombre",
                    1: "Llaves",
                    2: "Token",
                    3: "Secreto",
                    4: "Acciones"
                },

                delete: "Eliminar"
            },

            addKeyForm: {
                label: "Por favor ingrese la etiqueta (nombre) para la nueva clave de API",
                submit: "Crear API KEY",

                apiKey: "Clave de API"
            },

            confirmCode: {
                title: "Verificación de seguridad",
                subtext: "Para asegurar su cuenta, complete la siguiente verificación.",
                body: {
                    subtext: "Compruebe las carpetas de entrada y correo no deseado de su buzón",
                    h5: "Código de verificación por correo electrónico",
                    placeholder: "PIN del correo electrónico"
                },
                submit: "Enviar"
            },

            displayCopyKeyModal: {
                title: "API KEY generado",

                text: "Guarde esta clave secreta en un lugar seguro y accesible. Por motivos de seguridad, no podrá verla nuevamente. Si pierde esta clave secreta, deberá generar una nueva.",

                token: "Token",
                secret: "Secreto",

                submit: "COPIAR CÓDIGOS",

                copyText: "Llaves de API de Liqpaid",

                copy: {
                    success: "La clave fue copiada al portapapeles",
                    error: "Error al copiar el texto"
                }
            }
        },

        logout: {
            success: "Cierre de sesión exitoso"
        },

        footer: {
            copyright: "Servicio de pago por"
        },
    },

    invoice: {
        timerMessages: {
            1: "Esperando pago...",
            2: "Factura vencida"
        },
        payWith: "Pagar con",
        invoice: "Factura",
        orderAmount: "Monto de la orden",
        alreadyPaid: "Ya pagado",
        totalFee: "Tarifa total",
        due: "Vencimiento",
        scanTab: {
            name: "Escanear",
            openWallet: "Abrir en billetera"
        },
        copyTab: {
            name: "Copiar",
            info: {
                0: "Para completar su pago, por favor envíe",
                1: "a la dirección de abajo."
            },
            copied: "Copiado",
            amount: "Monto",
            address: "Dirección",
            link: "Enlace de pago"
        },
        transactionSuccess: {
            header: "Transacción exitosa",
            text: "Your payment has been credited into this invoice"
        },
        expired: {
            header: "¿Qué sucedió?",
            text: {
                0: "Esta factura ha vencido. Una factura solo es válida por %s. ",
                1: "Puede regresar si desea enviar su pago nuevamente.",
                2: "Si intentó enviar un pago, aún no ha sido aceptado por la red. Todavía no hemos recibido sus fondos."
            },
            minutes: 'минут',
            hours: 'horas',
            id: "ID de factura"
        },
        refund: {
            header: "Contacto y correo electrónico de reembolso",
            label: "Por favor, proporcione una dirección de correo electrónico a continuación. Nos pondremos en contacto con usted en esta dirección si hay algún problema con su pago.",
            errorLabel: "Por favor ingrese una dirección de correo electrónico válida",
            continue: "Continuar"
        },
        footer: "Desarrollado por",
    },

    invoicePay: {
        form: {
            1: "Por favor, ingrese su correo electrónico...",
            info: "Monto del pago",
            2: "Elija la moneda"
        },
        payWith: "Pagar con"
    },

    serverError: "Se produjo un error interno del servidor",
    copyError: "Error al copiar el texto"
}
