export default {
    header: {
        login: "Connexion",
        signUp: "S'inscrire"
    },
    login: {
        h2: "Se connecter à un compte",
        placeholder: {
            email: "E-mail",
            password: "Mot de passe",
            code_email: "Entrez le code de l'e-mail",
            code_google: "Entrez le code de Google Authenticator"
        },
        forgot: "Mot de passe oublié?",
        signUp: {
            text: "Pas encore membre ?",
            link: "S'inscrire"
        },
        submit: "Connexion",
        validation: {
            req: "Ce champ est requis",
            email: "Ce champ doit être un email valide",
            password: "Ce champ doit être un mot de passe valide (a-z, A-Z, 0-9)",
            code: "Ce champ doit être un code à 4 chiffres"
        },
        errors: { check: "Vérifier le formulaire" }
    },
    twoFaLinkActivation: {
        title: "Authentification à deux facteurs",
        text: "Veuillez patienter..."
    },
    register: {
        h2: "Créer un compte",
        placeholder: {
            email: "E-mail",
            phone: "Téléphone",
            password_repeat: "Répéter le mot de passe",
            password: "Mot de passe"
        },
        login: {
            text: "Déjà membre ?",
            link: "Connexion"
        },
        submit: "Créer un compte",
        validation: {
            req: "Ce champ est requis",
            email: "Ce champ doit être un email valide",
            phone: "Ce champ doit être un numéro de téléphone valide",
            password: "Ce champ doit être un mot de passe valide (a-z, A-Z, 0-9)"
        },
        confirmation: {
            h1: "Presque fini",
            p: ["Veuillez cliquer sur le lien d'activation dans votre email",
                "pour confirmer l'inscription"], link: "Aller à la connexion"
        },
        errors: { check: "Vérifier le formulaire" }
    },

    home: {
        promoSection: {
            h1: "Portail de traitement de paiements Bitcoin et portefeuille crypto-monnaie d'entreprise",
            descLeft: {
                u: "Accepter des paiements en Bitcoin",
                text: "Ethereum, USDT et autres devises numériques avec un portail de paiement crypto-monnaie leader."
            },
            descRight: "Recevoir, envoyer, stocker, échanger et payer en crypto-monnaie avec un portefeuille crypto-monnaie audité pour la sécurité."
        },

        h2: "Tous les services crypto pour les entreprises au même endroit",

        servicesSection: {
            0: {
                title: "Passerelle de paiement",
                desc: "Notre système de paiement crypto aide tout type d'entreprise en ligne",
                u: "accepter des paiements crypto",
                desc2: "et recevez immédiatement un montant égal dans la monnaie nationale de leur choix ou conservez-la et payez avec crypto à leurs partenaires et affiliés."
            },
            1: {
                title: "Solution SaaS crypto",
                desc: "C'est une opportunité abordable pour démarrer leur propre entreprise de paiement en devises numériques et devenir un système de paiement crypto ou un service de cryptomonnaie pour les commerçants sous leur propre marque sans effort."
            },
            2: {
                title: "Bourse",
                title2: "Bureau OTC",
                desc: "Les entreprises peuvent échanger BTC, ETH, USDT, XRP et plus de 20 autres cryptomonnaies contre des dizaines de devises nationales aux meilleurs taux et sans frais cachés. Ramps d'entrée et de sortie Fiat supportées.",
                desc2: "OTC est disponible pour des opérations d'échange supérieures à 1M EUR."
            },
            h3: "Apprenez comment nos solutions peuvent aider votre croissance commerciale",
            3: {
                title: "Portefeuille chaud (garde)",
                desc: "Système de portefeuille crypto-monnaie conçu pour les entreprises dont le modèle nécessite un environnement évolutif et sécurisé pour la gestion des actifs numériques (acceptation, stockage et retrait d'actifs numériques vers plusieurs adresses)."
            }
        },

        h3: {
            0: "Approche personnelle",
            1: "à l'adoption de Bitcoin",
            manage: "Gérez vos actifs numériques dans une application de portefeuille crypté auditée en matière de sécurité."
        },
        wallet: {
            title: "portefeuille crypto",
            desc: {
                0: "Recevez, stockez et envoyez plus de 15 cryptomonnaies sur 5 chaînes de blocs différentes dans le meilleur portefeuille crypto. Échangez tous les actifs numériques pris en charge contre 16 devises fiat aux meilleurs taux.",
                1: "L'achat de Bitcoin et d'une autre crypto avec des cartes bancaires arrive bientôt."
            }
        }
    },

    dashboard: {
        sidebar: {
            title: "Tableau de bord du marchand",
            links: {
                dashboard: "Tableau de bord",
                profile: "Profil",
                templates: "Modèles de facture",
                invoices: "Factures",
                addresses: "Adresses",
                wallets: "Portefeuilles / Retrait",
                withdrawRequests: "Demandes de retrait",
                transactions: "Transactions",
                callbacks: "Webhooks / Rappels",
                api: "Gestion de l'API",
                logout: "Se déconnecter"
            }
        },

        main: {
            cards: {
                totalAvailableBalance: {
                    title: "Solde disponible total",
                    footerText: "Tous les temps"
                },
                revenue: {
                    title: "Revenu",
                    footerLinks: {
                        0: "Tous les temps",
                        1: "Mois",
                        2: "Semaine",
                        3: "Aujourd'hui"
                    }
                }
            }
        },

        profile: {
            title: "Profil",
            subtitle: "Profil",

            info: {
                title: "Informations utilisateur",
                email: "Email"
            },

            lang: {
                title: "Langue",
                change: "Changer la langue"
            },

            expirations: {
                invoice: {
                    title: "Échéances",
                    change: "Expiration de la facture"
                },

                invoiceAddress: {
                    title: "Expiration de l'adresse de facturation",
                    hours: "heures"
                },

                minutes: "minutes",

                info: "Si votre client paye accidentellement après l'expiration de la facture et si le taux de change a baissé, le paiement ne sera pas échangé et vous pourrez rembourser votre client. Si le taux de change a augmenté, le paiement sera échangé.",

                save: "Enregistrer les paramètres",

                error: "La date d'expiration de la facture doit être inférieure ou égale à la date d'expiration de l'adresse de facturation"
            }

        },

        routeNames: {
            dashboard: "Tableau de bord",
            ProfilePage: "Profil",
            InvoiceTemplatesList: "Liste des modèles de facture",
            InvoicesForm: "Formulaire de facture",
            InvoicesList: "Liste des factures",
            InvoiceTemplatesCreateForm: "Formulaire de modèles de facture",
            InvoiceTemplatesEditForm: "Formulaire de modèles de facture",
            AddressesList: "Liste d'adresses",
            WalletsList: "Liste des portefeuilles",
            WithdrawRequest: "Demande de retrait",
            WithdrawRequestConfirm: "Confirmation de la demande de retrait",
            WithdrawTransactions: "Transactions de retrait",
            TransactionsList: "Liste des transactions",
            ApiManagement: "Gestion de l'API",
            CallbacksList: "Liste de rappels"
        },

        templates: {
            table: {
                0: "ID",
                1: "Nom du modèle",
                2: "Devise",
                3: "Montant demandé",
                4: "Statut",
                5: "Actions"
            },
            statuses: {
                0: "Créé",
                1: "En attente de dépôt",
                2: "Annulé",
                3: "Complété"
            },
            noRecords: "Aucun enregistrement",
            title: "Liste des modèles de facture",
            subtitle: "Liste des modèles de facture",
            createInvoiceTemplate: "Créer un modèle de facture",
            cardTemplate: {
                0: "Transactions",
                1: "Code d'intégration",
                2: "Lien",
                3: "Supprimer"
            },
            copy: {
                0: "Lien pour le modèle de facture",
                1: "a été copié dans le presse-papiers",
            },
            invoiceWidget: {
                title: "Paiement",
                prodPurchase: "Achat de produit",
                btnSuccess: "Aller au paiement",
                label: {
                    email: "Veuillez saisir votre adresse e-mail",
                    choose: "Choisissez la pièce à payer avec"
                },
                amount: "Montant du paiement"
            },
            widgetModal: {
                title: "Code de widget de facture",
                copy: {
                    success: "Le code du widget a été copié dans le presse-papiers",
                    error: "Erreur lors de la copie du texte"
                }
            }
        },

        invoiceTemplatesForm: {
            title: "Créer un modèle de facture",
            label: {
                name: "Nom du modèle de facture",
                convert: "Convertir les paiements en",
                price: "Prix en",
                currency: "Recevoir les paiements en",
                amount: "Montant demandé"
            },
            placeholder: {
                name: "Nom du modèle",
                price: "Prix en",
                currency: "Accepter toutes les pièces",
                amount: "Montant demandé"
            },
            success: {
                0: "Le lien pour la facture",
                1: "a été copié dans le presse-papiers",
            },
            submit: "Enregistrer et copier le lien de la facture"
        },
        invoicesList: {
            title: "Liste des factures",
            subtitle: "Liste des factures",
            table: {
                0: "ID",
                1: "Devise",
                2: "Montant de la facture",
                3: "Statut",
                4: "Date de création",
                5: "Actions",
            },
            link: "Lien",
            copy: {
                0: "Lien de la facture",
                1: "a été copié dans le presse-papiers",
                error: "Erreur de copie du texte"
            },
            createInvoice: "Créer une facture"
        },
        invoicesForm: {
            title: "Créer une facture",
            label: {
                currency: "Devise",
                amount: "Montant demandé"
            },
            placeholder: {
                currency: "Choisir une devise",
                amount: "Montant demandé"
            },
            success: {
                0: "La facture a été créée et le lien pour la facture",
                1: "a été copié dans le presse-papiers",
            },
            submit: "Enregistrer et copier le lien de la facture"
        },

        addresses: {
            statuses: {
                0: "Désactivé",
                1: "Actif",
                2: "Deprecated"
            },
            list: {
                title: "Liste des adresses",
                subtitle: "Liste des adresses",
                create: "Créer une adresse",
                edit: "Modifier",
                delete: "Supprimer",

                table: {
                    0: "ID",
                    1: "Nom",
                    2: "Devise",
                    3: "Convertir en",
                    4: "Montant minimum de recharge",
                    5: "Adresse",
                    6: "Statut",
                    7: "Action"
                },
            },
            form: {
                title: "Créer une adresse",
                label: {
                    label: "Nom",
                    currency: "Devise",
                    convert_to: "Convertir en:",
                    network_type: "Type de réseau"
                },
                placeholder: {
                    label: "Nom",
                    currency: "Devise",
                    convert_to: "Ne convertissez pas",
                    network_type: "Type de réseau"
                },
                submit: "Enregistrer l'adresse"
            }
        },

        wallets: {
            title: "Liste des Soldes de Portefeuille",
            subtitle: "Liste des Soldes de Portefeuille",
            tableColumns: {
                0: "Devise",
                1: "Solde Total",
                2: "Solde Disponible",
                3: "Retrait",
                4: "Swap"
            },

            withdraw: "Retrait",
            swap: "Swap"
        },

        walletsRequest: {
            title: "Créer une demande de retrait",
            label: {
                networkType: "Type de réseau",
                address: "Adresse",
                amount: "Montant",
                iban: "IBAN"
            },
            placeholder: {
                networkType: "Type de réseau",
                address: "Adresse",
                amount: "Montant",
                iban: "IBAN"
            },
            submit: "Retrait",

            detailTitle: 'Withdrawal Detail',

            confirmCode: {
                title: "Confirmation de la demande de retrait",
                text: "Veuillez confirmer le retrait",
                labelEmail: "Vérifiez votre e-mail et entrez le code",
                labelEmailSub: 'The code is valid for 5 minutes',
                labelGoogle: "Entrez le code à l'aide de Google Authenticator",
                placeholderEmail: 'Entrez le code du courrier',
                placeholderGoogle: "Entrez le code de Google Authenticator"
            },
        },

        walletsRequestsList: {
            title: "Liste des Demandes de Retrait",
            subtitle: "Liste des Demandes de Retrait",

            tableColumns: {
                0: "ID",
                1: "Montant",
                2: "Devise",
                3: "Commission",
                4: "Adresse",
                5: "Statut",
                6: "Date",
                7: "Network"
            },

            statuses: {
                0: 'Créé',
                1: 'Envoyé',
                2: 'En attente',
                3: 'En cours de révision',
                4: 'Traitement',
                5: 'Confirmation',
                6: 'Supprimé',
                7: 'Annulé',
                11: 'Retrait en cours',
                12: 'Retrait en cours de traitement'
            }
        },

        transactionsList: {
            title: "Liste des Transactions",
            subtitle: "Liste des Transactions",

            tableColumns: {
                id: "Identifiant",
                txid: "Hash",
                invoice_id: "Identifiant de facture",
                direction: "Direction",
                amount_paid: "Montant payé",
                fee: "Commission",
                amount_converted: "Converti en",
                convert_rate: "Taux de conversion",
                item_name: "Nom de l'article",
                address_name: "Nom de l'adresse",
                address_deposit: "Dépôt d'adresse",
                user_email: "Email client",
                time_created: "Date de création",
                address_from: "Adresse d'expédition",
                action: "Action",
                note: "Comment"
            },
            noteModal: {
                title: 'Transaction Comment',
                submit: 'Save Note'
            }
        },

        invoices: {
            statuses: {
                0: "Créé",
                1: "En attente de dépôt",
                2: "Expiré",
                3: "Rempli",
                4: "Expiré",
                5: "Adresse expirée",
                partiallyPaid: 'Partially Paid'
            }
        },

        callbacks: {
            statuses: {
                0: 'Désactivé',
                1: 'Actif'
            },

            form: {
                name: "Veuillez entrer l'URL de votre webhook",
                button: "Ajouter l'URL du rappel",
                urlError: "L'URL de rappel est manquante"
            },

            list: {
                title: "Liste des Webhooks de rappel",
                subtitle: "Liste des Webhooks de rappel",

                tableColumns: {
                    0: "ID",
                    1: "URL de Webhook",
                    2: "Statut",
                    3: "Actions",
                },

                delete: "Supprimer"
            },

            copySecret: {
                title: "Rappel enregistré",
                info: "Veuillez enregistrer cette clé secrète quelque part de sûr et accessible. Pour des raisons de sécurité, vous ne pourrez plus la voir. Si vous perdez cette clé secrète, vous devrez en générer une nouvelle.",

                secret: "Secret",
                callbackSecret: "Secret de rappel Liqpaid",

                copy: {
                    btn: "COPIER LE SECRET DE RAPPEL",
                    success: "Le secret de rappel a été copié dans le presse-papiers",
                    error: "Erreur lors de la copie du texte",
                }
            }
        },

        apiManagement: {
            list: "Liste des clés",
            link: "Documentation de l'API",

            title: "Gestion de l'API",
            info: "La création d'une clé privée d'API permet d'accéder aux marchés et aux services de trading en temps réel sur LiqPaid via un site ou une application tiers.",

            keysList: {
                title: "Liste des clés",
                subtitle: "Liste des clés",

                tableColumns: {
                    0: "Nom",
                    1: "Clés",
                    2: "Jeton",
                    3: "Secret",
                    4: "Actions"
                },

                delete: "Supprimer"
            },

            addKeyForm: {
                label: "Veuillez entrer l'étiquette (nom) de la nouvelle clé d'API",
                submit: "Créer une clé d'API",

                apiKey: "Clé API"
            },

            confirmCode: {
                title: "Vérification de sécurité",
                subtext: "Pour sécuriser votre compte, veuillez effectuer la vérification suivante.",
                body: {
                    subtext: "Vérifiez les dossiers de réception et de courrier indésirable de votre boîte aux lettres",
                    h5: "Code de vérification par e-mail",
                    placeholder: "PIN de l'e-mail"
                },
                submit: "Soumettre"
            },

            displayCopyKeyModal: {
                title: "Clé d'API générée",

                text: "Veuillez enregistrer cette clé secrète quelque part de sûr et accessible. Pour des raisons de sécurité, vous ne pourrez plus la voir. Si vous perdez cette clé secrète, vous devrez en générer une nouvelle.",

                token: "Jeton",
                secret: "Secret",

                submit: "COPIER LES CODES",

                copyText: "Clés API Liqpaid",

                copy: {
                    success: "La clé a été copiée dans le presse-papiers",
                    error: "Erreur de copie de texte"
                }
            }
        },

        logout: {
            success: "Déconnexion réussie"
        },

        footer: {
            copyright: "Service de paiement par"
        },
    },

    invoice: {
        timerMessages: {
            1: "En attente de paiement...",
            2: "La facture a expiré"
        },

        payWith: "Payer avec",
        invoice: "Facture",
        orderAmount: "Montant de la commande",
        alreadyPaid: "Déjà payé",
        totalFee: "Frais totaux",
        due: "Dû",

        scanTab: {
            name: "Scanner",
            openWallet: "Ouvrir dans le portefeuille"
        },

        copyTab: {
            name: "Copier",
            info: {
                0: "Pour finaliser votre paiement, veuillez envoyer",
                1: "à l'adresse ci-dessous."
            },
            copied: "Copié",
            amount: "Montant",
            address: "Adresse",
            link: "Lien de paiement"
        },
        transactionSuccess: {
            header: "Transaction réussie",
            text: "Your payment has been credited into this invoice"
        },

        expired: {
            header: "Qu'est-il arrivé ?",
            text: {
                0: "Cette facture a expiré. Une facture n'est valable que %s. ",
                1: "Vous pouvez revenir si vous souhaitez soumettre à nouveau votre paiement.",
                2: "Si vous avez essayé d'envoyer un paiement, il n'a pas encore été accepté par le réseau. Nous n'avons pas encore reçu vos fonds."
            },
            minutes: 'минут',
            hours: 'heures',
            id: "Identifiant de facture"
        },

        refund: {
            header: "Contact et email de remboursement",

            label: "Veuillez fournir une adresse e-mail ci-dessous. Nous vous contacterons à cette adresse en cas de problème avec votre paiement.",

            errorLabel: "Veuillez saisir une adresse e-mail valide",
            continue: "Continuer"
        },

        footer: "Propulsé par",
    },

    invoicePay: {
        form: {
            1: "Veuillez entrer votre adresse e-mail...",
            info: "Montant du paiement",
            2: "Choisissez la pièce"
        },
        payWith: "Payer avec"
    },

    serverError: "Une erreur interne du serveur s'est produite",
    copyError: "Erreur lors de la copie du texte"
}
