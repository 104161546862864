import axios from '../../utils/api';
import {formatDate, formattedNumFromInt} from "@/utils/Formatter";

export default {
    state: {
        list: [],
        pagination: {
            page: 0,
            totalCountPage: 0
        },
    },
    getters: {
        getTransactionList: (state) => state.list,
        getTransactionListPagination:  (state) => state.pagination,
    },
    mutations: {
        SET_TRANSACTIONS_LIST(state, data) {
            state.list = data;
        },
        SET_TRANSACTIONS_PAGINATION(state, data) {
            state.pagination = data;
        }
    },
    actions: {
        loadTransactions(context, params = {}) {
            // Do get data
            return axios.get('/invoices/index/get-transactions-list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        const currenciesList = context.getters.getCurrenciesList;

                        // Transform items on push to table data
                        const tableData = [];
                        for (const [key] of Object.entries(data.data.list)) {
                            const item = data.data.list[key];

                            let currency_paid = null;

                            if(item.invoice && item.invoice.currency_id){
                                currency_paid = currenciesList[item.invoice.currency_id];
                            }

                            if(item.address && item.address.currency_id){
                                currency_paid = currenciesList[item.address.currency_id];
                            }

                            const amount_paid = currency_paid ? formattedNumFromInt(item.amount_plus_fee, currency_paid.decimal) + ' ' + currency_paid.iso3 : null;

                            let amount_converted = amount_paid;
                            if(item.converted_to){
                                // const currency_converted = currenciesList[item.invoice.convert_to_currency_id];
                                amount_converted = formattedNumFromInt(item.converted_to.converted_to_amount, item.converted_to.converted_to_currency.decimal) + ' ' + item.converted_to.converted_to_currency.iso3
                            }

                            tableData.push({
                                id: item.id,
                                txid: item.txid,
                                invoice_id: item.invoice_id,
                                amount_paid: amount_paid,
                                fee: currency_paid ? formattedNumFromInt(item.fee, currency_paid.decimal) + ' ' + currency_paid.iso3 : null,
                                amount_converted: amount_converted,
                                convert_rate: item.order_after_deposit_rate_done ? formattedNumFromInt(item.order_after_deposit_rate_done, 2) : null,
                                item_name: item.invoice ? item.invoice.item_name : null,
                                address_name: item.address ? item.address.label : null,
                                address_from: item.address_from,
                                user_email: item.user_email,
                                time_created: formatDate(item.time_create),
                                status: item.status,
                                order_status: item.coin_order_statusLabel ? item.coin_order_statusLabel : '-',
                                explorer_link: item.explorer_link,
                                address_deposit: item.address_to,
                                direction: (item.invoice || item.address) ? 'Inbound' : 'Outbound',
                                note: item.user_comment
                            });
                        }

                        context.commit('SET_TRANSACTIONS_LIST', tableData);
                        context.commit('SET_TRANSACTIONS_PAGINATION', data.data.pagination);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadTransactionsForExport() {
            const params = {export: 1}

            // Do get data
            return axios.get('/invoices/index/get-transactions-list', {params})
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateTransactionComment(context, data = {}) {
            // Do send data
            return axios.post('/invoices/transactions/update-comment', data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
