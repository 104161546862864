import axios from '../../utils/api';
import i18n from '@/translation';
import {formatExponentialNumber} from "@/utils/Formatter";

const { t } = i18n.global;

export default {
    state: {
        list: [],
        pagination: {
            page: 0,
            totalCountPage: 0
        },
    },
    getters: {
        getAddressesList: (state) => state.list,
        getAddressesListPagination: (state) => state.pagination,
    },
    mutations: {
        SET_ADDRESSES_LIST(state, data) {
            state.list = data;
        },
        SET_ADDRESSES_PAGINATION(state, data) {
            state.pagination = data;
        },
    },
    actions: {
        loadAddresses(context, params = {}) {
            context.dispatch('loadConfigs').then(r => {
                if (!r) {
                    this.$toast.error('An internal server error occurred');

                    return false;
                }

                // Do get data
                axios.get('/invoices/addresses/get-list', {params})
                    .then((response) => {
                        const data = response.data;

                        if (data.status) {
                            const currenciesList = context.getters.getCurrenciesList;
                            const networkAliases = context.getters.getNetworkAliases;

                            // Transform items on push to table data
                            let list = [];
                            for (const [key] of Object.entries(data.data.list)) {
                                const item = data.data.list[key];

                                let currency = currenciesList[item.currency_id];

                                if(!currency){
                                    currency = context.getters.getCurrencyFromAllList(item.currency_id);

                                    item.status = 2;
                                }

                                // Detect network name
                                let networkName = ''
                                const network = currency && currency.networks && currency.networks[item.network_type]
                                    ? currency.networks[item.network_type]
                                    : null;

                                if(network){
                                    networkName = networkAliases[network.name];
                                    if(networkName === undefined){
                                        networkName = Object.keys(currency.networks).length > 0 ? network.name : '';
                                    }
                                }

                                item.currency = currency?.iso3;
                                item.currency_network = currency && Object.keys(currency.networks).length > 1 ? `${item.currency} ${networkName}` : item.currency;
                                item.convert_to_currency = currenciesList[item.convert_to_currency_id]?.iso3 || '';
                                item.status_label = t(`dashboard.addresses.statuses.${item.status}`);
                                item.minimum_deposit = network ? formatExponentialNumber(network.deposit?.min_amount) : 0;

                                list.push(item);
                            }

                            context.commit('SET_ADDRESSES_LIST', list);
                            context.commit('SET_ADDRESSES_PAGINATION', data.data.pagination);
                        }

                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        },
        async createAddress(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/addresses/create', data)
                .then((response) => {
                    if (response.data.status && response.data.data) {
                        //
                    }

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async updateAddress(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/addresses/update', data)
                .then((response) => {
                    if (response.data.status && response.data.data) {
                        //
                    }

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deleteAddress(context, data = {}){
            // Do send data
            return await axios.post('/invoices/addresses/delete', data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
