export default {
    header: {
        login: "Войти",
        signUp: "Регистрация"
    },
    login: {
        h2: "Войти в аккаунт",
        placeholder: {
            email: "Эл. почта",
            password: "Пароль",
            code_email: "Введите код из электронной почты",
            code_google: "Введите код из Google Authenticator"
        },
        forgot: "Забыли пароль?",
        signUp: {
            text: "Не участник?",
            link: "Зарегистрироваться"
        },
        submit: "Войти",
        validation: {
            req: "Это поле обязательно для заполнения",
            email: "Это поле должно быть действительным адресом электронной почты",
            password: "Это поле должно быть действительным паролем (a-z, A-Z, 0-9)",
            code: "Это поле должно содержать 4-значный код."
        },
        errors: {
            check: "Проверьте поле формы"
        }
    },

    twoFaLinkActivation: {
        title: "Двухфакторная аутентификация",
        text: "Пожалуйста, подождите..."
    },

    register: {
        h2: "Создать аккаунт",
        placeholder: {
            email: "Эл. почта",
            phone: "Телефон",
            password_repeat: "Повторить пароль",
            password: "Пароль"
        },

        login: {
            text: "Уже участник?",
            link: "Войти"
        },

        submit: "Создать аккаунт",

        validation: {
            req: "Это поле обязательно для заполнения",
            email: "Это поле должно быть действительным адресом электронной почты",
            phone: "Это поле должно быть действительным номером телефона",
            password: "Это поле должно быть действительным паролем (a-z, A-Z, 0-9)"
        },

        confirmation: {
            h1: "Почти готово",
            p: {
                0: "Пожалуйста, нажмите на ссылку активации в своем письме",
                1: "для подтверждения регистрации"
            },
            link: "Перейти к Входу"
        },
        errors: {
            check: "Проверьте поле формы"
        }
    },

    home: {
        promoSection: {
            h1: "Шлюз обработки платежей в Биткоинах и бизнес-крипто-кошелек",
            descLeft: {
                u: "Принимайте платежи в Bitcoin",
                text: "Ethereum, USDT и другие цифровые валюты с ведущим шлюзом для криптовалют."
            },
            descRight: "Получайте, отправляйте, храните, обменивайте и платите в криптовалюте с защищенным аудитом крипто-кошелька."
        },

        h2: "Все сервисы для бизнеса в одном месте",

        servicesSection: {
            0: {
                title: "Платежный шлюз",
                desc: "Наша система крипто-платежей помогает любому онлайн-бизнесу",
                u: "принимать крипто-платежи",
                desc2: "и мгновенно получать равный объем в национальной валюте по своему выбору или хранить его и платить крипто-валютой своим партнерам и аффилиатам.",
            },
            1: {
                title: "Крипто-решение SaaS",
                desc: "Это экономичная возможность для запуска своего бизнеса по приему платежей в крипто-валюте и стать платежной системой или сервисом крипто-валют для предпринимателей под вашим собственным брендом."
            },
            2: {
                title: "Обменник",
                title2: "OTC доска",
                desc: "Предприятия могут обменивать BTC, ETH, USDT, XRP и 20+ других криптовалют на десятки национальных валют по лучшим курсам без скрытых платежей. Поддерживаются вход и выход Fiat.",
                desc2: "OTC доступен для операций обмена в количестве более 1M EUR.",
            },
            h3: "Узнайте, как наши решения помогают росту вашего бизнеса.",
            3: {
                title: "Горячий кошелек (кастодиан)",
                desc: "Система крипто-валютного горячего кошелька, нацеленная на бизнесы, чья модель требует масштабируемой и безопасной среды для управления цифровыми активами (прием, хранение и вывод цифровых активов на несколько адресов)."
            }
        },

        h3: {
            0: "Свой подход",
            1: "к принятию BTC",
            manage: "Управляйте своими цифровыми активами в безопасном аудитируемом приложении криптокошелька."
        },

        wallet: {
            title: "криптокошелек",
            desc: {
                0: "Получайте, храните и отправляйте 15+ криптовалют на 5 разных блокчейнах в лучшем криптокошельке. Обменивайте все поддерживаемые цифровые активы на 16 фиатных валют по лучшим курсам.",
                1: "Купить Bitcoin и другую криптовалюту с банковскими картами появится позднее."
            }
        },
    },

    dashboard: {
        sidebar: {
            title: "Панель продавца",

            links: {
                dashboard: "Главная",
                profile: "Профиль",
                templates: "Шаблоны счетов",
                invoices: "Счета",
                addresses: "Адреса",
                wallets: "Кошельки / Вывод",
                withdrawRequests: "Запросы на вывод",
                transactions: "Транзакции",
                callbacks: "Вебхуки / Колбэки",
                api: "Управление API",
                logout: "Выйти",
            },
        },

        main: {
            cards: {
                totalAvailableBalance: {
                    title: "Общий доступный баланс",
                    footerText: "Все время"
                },
                revenue: {
                    title: "Доход",
                    footerLinks: {
                        0: "Все время",
                        1: "Месяц",
                        2: "Неделя",
                        3: "Сегодня",
                    }
                }
            }
        },

        profile: {
            title: "Профиль",
            subtitle: "Профиль",

            info: {
                title: "Информация о пользователе",
                email: "Электронная почта"
            },

            lang: {
                title: "Язык",
                change: "Изменить язык"
            },

            expirations: {
                invoice: {
                    title: "Срок годности",
                    change: "Истек срок годности счета",
                },

                invoiceAddress: {
                    title: "Истек срок годности адреса счета",
                    hours: "часов"
                },

                minutes: "минут",

                info: "Если ваш клиент случайно оплатит после истечения срока действия счета и курс обмена упадет, платеж не будет произведен, и вы сможете вернуть деньги клиенту. Если курс вырос, то платеж будет произведен.",

                save: "Сохранить настройки",

                error: "Срок действия счета-фактуры должен быть меньше или равен сроку действия адреса счета-фактуры"
            },
        },

        routeNames: {
            dashboard: "Панель управления",
            ProfilePage: "Профиль",
            InvoiceTemplatesList: "Список шаблонов счетов",
            InvoicesForm: "Форма счетов",
            InvoicesList: "Список счетов",
            InvoiceTemplatesCreateForm: "Форма шаблонов счетов",
            InvoiceTemplatesEditForm: "Форма шаблонов счетов",
            AddressesList: "Список адресов",
            WalletsList: "Список кошельков",
            WithdrawRequest: "Запрос на вывод средств",
            WithdrawRequestConfirm: "Подтверждение запроса на вывод средств",
            WithdrawTransactions: "Транзакции по выводу средств",
            TransactionsList: "Список транзакций",
            ApiManagement: "Управление API",
            CallbacksList: "Список Callback",
        },

        templates: {
            table: {
                0: "ID",
                1: "Имя Шаблона",
                2: "Валюта",
                3: "Запрошенная сумма",
                4: "Статус",
                5: "Действия",
            },

            statuses: {
                0: "Создано",
                1: "Ожидание депозита",
                2: "Отменено",
                3: "Полностью выполнено"
            },

            noRecords: "Нет записей",

            title: "Список шаблонов счетов",
            subtitle: "Список шаблонов счетов",

            createInvoiceTemplate: "Создать шаблон счета",

            cardTemplate: {
                0: "Транзакции",
                1: "Код для вставки",
                2: "Ссылка",
                3: "Удалить",
            },

            copy: {
                0: "Ссылка на шаблон счета",
                1: "было скопировано в буфер обмена",
                error: "Ошибка в копировании текста"
            },

            invoiceWidget: {
                title: "Оплата",
                prodPurchase: "Покупка продукта",
                btnSuccess: "Перейти к оплате",
                label: {
                    email: "Пожалуйста, введите свой адрес электронной почты",
                    choose: "Выберите монету для оплаты"
                },
                amount: "Сумма оплаты"
            },

            widgetModal: {
                title: "Код виджета счета",
                copy: {
                    success: "Код виджета был скопирован в буфер обмена",
                    error: "Ошибка при копировании текста"
                }
            },
        },

        invoiceTemplatesForm: {
            title: "Создать шаблон счета",

            label: {
                name: "Название шаблона счета",
                convert: "Преобразовать платежи в",
                price: "Цена в",
                currency: "Получать платежи в",
                amount: "Запрошенная сумма"
            },

            placeholder: {
                name: "Название шаблона",
                price: "Цена в",
                currency: "Принимать все монеты",
                amount: "Запрошенная сумма"
            },

            success: {
                0: "Ссылка на шаблон счета",
                1: "скопирована в буфер обмена",
            },

            submit: "Сохранить и скопировать ссылку на счет"
        },

        invoicesList: {
            title: "Список счетов",
            subtitle: "Список счетов",

            table: {
                0: "ID",
                1: "Валюта",
                2: "Сумма счета",
                3: "Статус",
                4: "Дата создания",
                5: "Действия",
            },

            link: "Ссылка",

            statuses: {
                0: "Создано",
                1: "Ожидание депозита",
                2: "Отменено",
                3: "Полностью выполнено"
            },

            copy: {
                0: "Ссылка на шаблон счета",
                1: "было скопировано в буфер обмена",
            },

            createInvoice: "Создать счет"
        },

        invoicesForm: {
            title: "Создать счет",

            label: {
                currency: "Валюта",
                amount: "Запрошенная сумма"
            },

            placeholder: {
                currency: "Выбрать валюту",
                amount: "Запрошенная сумма"
            },

            success: {
                0: "Счет создан и ссылка на счет",
                1: "скопирована в буфер обмена",
            },

            submit: "Сохранить и скопировать ссылку на счет"
        },

        addresses: {
            statuses: {
                0: "Отключен",
                1: "Активен",
                2: "Запрещен"
            },

            list: {
                title: "Список адресов",
                subtitle: "Список адресов",
                create: "Создать адрес",
                edit: "Редактировать",
                delete: "Удалить",

                table: {
                    0: "ID",
                    1: "Название",
                    2: "Валюта",
                    3: "Конвертировать в",
                    4: "Минимальная пополнение",
                    5: "Адрес",
                    6: "Статус",
                    7: "Действие"
                },
            },

            form: {
                title: "Создать адрес",
                label: {
                    label: "Имя",
                    currency: "Валюта",
                    convert_to: "Конвертировать в:",
                    network_type: "Тип сети"
                },
                placeholder: {
                    label: "Имя",
                    currency: "Валюта",
                    convert_to: "Не конвертировать",
                    network_type: "Тип сети"
                },
                submit: "Сохранить адрес"
            }
        },

        wallets: {
            title: "Список балансов кошельков",
            subtitle: "Список балансов кошельков",
            tableColumns: {
                0: "Валюта",
                1: "Общий баланс",
                2: "Доступный баланс",
                3: "Вывод",
                4: "Обмен"
            },
            withdraw: "Вывод",
            swap: "Swap"
        },

        walletsRequest: {
            title: "Создать запрос на вывод",
            label: {
                networkType: "Тип сети",
                address: "Адрес",
                amount: "Сумма",
                iban: "IBAN"
            },
            placeholder: {
                networkType: "Тип сети",
                address: "Адрес",
                amount: "Сумма",
                iban: "IBAN"
            },
            submit: "Вывод",

            detailTitle: 'Withdrawal Detail',

            confirmCode: {
                title: "Подтверждение запроса на вывод средств",
                text: "Пожалуйста, подтвердите вывод",
                labelEmail: "Проверьте свою электронную почту и укажите код",
                labelEmailSub: 'The code is valid for 5 minutes',
                labelGoogle: "Введите код с помощью Google Authenticator",
                placeholderEmail: 'Введите код из почты',
                placeholderGoogle: "Введите код из Google Authenticator"
            },
        },

        walletsRequestsList: {
            title: "Список запросов на вывод",
            subtitle: "Список запросов на вывод",
            tableColumns: {
                0: "ID",
                1: "Сумма",
                2: "Валюта",
                3: "Комиссия",
                4: "Адрес",
                5: "Статус",
                6: "Дата",
                7: "Сеть"
            },

            statuses: {
                0: "Создано",
                1: "Отправлено",
                2: "Ожидание",
                3: "На рассмотрении",
                4: "Обработка",
                5: "Подтверждение",
                6: "Удалено",
                7: "Отменено",
                11: "Вывод средств",
                12: "Блокировка процесса вывода средств"
            }
        },

        transactionsList: {
            title: "Список транзакций",
            subtitle: "Список транзакций",

            tableColumns: {
                id: "ID",
                txid: "Hash",
                invoice_id: "ID счета",
                direction: "Направление",
                amount_paid: "Оплаченная сумма",
                fee: "Комиссия",
                amount_converted: "Конвертировано в",
                convert_rate: "Курс конвертации",
                item_name: "Наименование товара",
                address_name: "Имя адресата",
                address_deposit: "Адрес депозита",
                user_email: "Email клиента",
                time_created: "Дата создания",
                address_from: "Адрес отправителя",
                action: "Действие",
                note: "Комментарий"
            },
            noteModal: {
                title: 'Transaction Comment',
                submit: 'Save Note'
            }
        },

        invoices: {
            statuses: {
                0: "Создан",
                1: "Ожидание депозита",
                2: "Истек срок",
                3: "Выполнено",
                4: "Истек срок",
                5: "Истек срок действия адреса",
                partiallyPaid: 'Частично оплачен'
            }
        },

        callbacks: {
            statuses: {
                0: 'Отключен',
                1: 'Активный'
            },

            form: {
                name: "Пожалуйста, введите URL вашего вебхука",
                button: "Добавить URL вебхука",
                urlError: "URL вебхука отсутствует"
            },

            list: {
                title: "Список вебхуков",
                subtitle: "Список вебхуков",

                tableColumns: {
                    0: "ID",
                    1: "URL вебхука",
                    2: "Статус",
                    3: "Действия",
                },

                delete: "Удалить"
            },

            copySecret: {
                title: "Вебхук сохранен",
                info: "Пожалуйста, сохраните этот секретный ключ в безопасном и доступном месте. По соображениям безопасности вы не сможете просмотреть его снова. Если вы потеряете этот секретный ключ, вам нужно будет сгенерировать новый.",

                secret: "Секретный ключ",
                callbackSecret: "Секретный ключ для вебхука",

                copy: {
                    btn: "СКОПИРОВАТЬ СЕКРЕТНЫЙ КЛЮЧ",
                    success: "Секретный ключ вебхука был скопирован в буфер обмена",
                    error: "Ошибка при копировании текста",
                }
            }
        },

        apiManagement: {
            list: "Список ключей",
            link: "Документация по API",

            title: "Управление API",
            info: "Создание закрытого ключа API обеспечивает доступ к рынкам и услугам реального времени на LiqPaid через сторонний сайт или приложение.",

            keysList: {
                title: "Список ключей",
                subtitle: "Список ключей",

                tableColumns: {
                    0: "Название",
                    1: "Ключи",
                    2: "Токен",
                    3: "Секретный ключ",
                    4: "Действия"
                },

                delete: "Удалить"
            },

            addKeyForm: {
                label: "Пожалуйста, введите метку (название) для нового ключа API",
                submit: "Создать API ключ",

                apiKey: "API-ключ"
            },

            confirmCode: {
                title: "Проверка безопасности",
                subtext: "Для обеспечения безопасности своего аккаунта выполните следующую проверку.",
                body: {
                    subtext: "Проверьте папки Входящие и Спам вашего email ящика",
                    h5: "Код подтверждения по электронной почте",
                    placeholder: "PIN из электронной почты"
                },
                submit: "Отправить"
            },

            displayCopyKeyModal: {
                title: "API KEY создан",

                text: "Пожалуйста, сохраните этот секретный ключ в безопасном и доступном месте. По соображениям безопасности вы не сможете просмотреть его снова. Если вы потеряете этот секретный ключ, вам придется создать новый.",

                token: "Токен",
                secret: "Секретный ключ",

                submit: "КОПИРОВАТЬ КОДЫ",

                copyText: "API-ключи Liqpaid",

                copy: {
                    success: "Ключ был скопирован в буфер обмена",
                    error: "Ошибка при копировании текста"
                }
            }
        },

        logout: {
            success: "Успешный выход"
        },

        footer: {
            copyright: "Платежный сервис от"
        }
    },

    invoice: {
        timerMessages: {
            1: "Ожидание оплаты...",
            2: "Срок действия счета истек"
        },
        payWith: "Оплатить через",
        invoice: "Счет",
        orderAmount: "Сумма заказа",
        alreadyPaid: "Уже оплачено",
        totalFee: "Общая комиссия",
        due: "Осталось оплатить",
        scanTab: {
            name: "Сканирование",
            openWallet: "Открыть кошелек"
        },
        copyTab: {
            name: "Копирование",
            info: {
                0: "Для завершения оплаты отправьте",
                1: "на указанный адрес."
            },
            copied: "Скопировано",
            amount: "Сумма",
            address: "Адрес",
            link: "Ссылка на оплату"
        },
        transactionSuccess: {
            header: "Транзакция успешна",
            text: "Your payment has been credited into this invoice"
        },
        expired: {
            header: "Что случилось?",
            text: {
                0: "Срок действия счета истек. Счет действителен только %s. ",
                1: "Вы можете вернуться, если хотите повторно оплатить заказ.",
                2: "Если вы пытались отправить оплату, то она еще не была принята сетью. Мы еще не получили ваши средства."
            },
            minutes: 'минут',
            hours: 'часов',
            id: "ID счета"
        },
        refund: {
            header: "Контактная информация и электронная почта для возврата средств",
            label: "Укажите адрес электронной почты ниже. Мы свяжемся с вами по этому адресу, если возникнут проблемы с оплатой.",
            errorLabel: "Пожалуйста, введите корректный адрес электронной почты",
            continue: "Продолжить"
        },
        footer: "Работает на"
    },

    invoicePay: {
        form: {
            1: "Пожалуйста, введите ваш адрес электронной почты...",
            info: "Сумма оплаты",
            2: "Выберите монету"
        },
        payWith: "Оплатить через"
    },

    serverError: "Произошла внутренняя ошибка сервера",
    copyError: "Ошибка в копировании текста"
}
